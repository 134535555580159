import GatsbyImage from "gatsby-image";
import { get } from "lodash";
import React, { useState } from "react";
import Footer from "../components/Layout/Footer";
import Navbar from "../components/Layout/Navbar";
import TopBar from "../components/Layout/TopBar";

import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";

import FilterListing from "../components/Layout/FilterListing";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import Navigation from "../components/Layout/Navigation";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import { graphql } from "gatsby";
export default function FilterPag({ data, pageContext }) {
  return (
    <>
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />
      <AnimateSharedLayout>
        {" "}
        <motion.div layout>
          <AnimatePresence initial={false}>
            <FilterListing
              data={data.stories}
              title={"Location: " + pageContext.location}
              filter={pageContext.location}
              type={"location"}
            />
          </AnimatePresence>
        </motion.div>
      </AnimateSharedLayout>
      <Footer></Footer>
    </>
  );
}
export const query = graphql`
  query location($location: String) {
    stories: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/src/storys/" }
        frontmatter: { location: { eq: $location } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          subtitle
          subtitle2
          tags
          slug
          location
          thumb {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
      }
    }
  }
`;
